import { ClickAnalyticsPlugin } from "@microsoft/applicationinsights-clickanalytics-js";
import { ApplicationInsights, ICustomProperties } from "@microsoft/applicationinsights-web";
import { User } from "@microsoft/microsoft-graph-types";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useLogin } from "./accountService";
import { useConfig } from "./authConfig";

const clickPluginInstance = new ClickAnalyticsPlugin();
const clickPluginConfig = {
  autoCapture: true,
  dataTags: {
    useDefaultContentNameOrId: true,
  },
};

let appInsights: ApplicationInsights;

export const getAppInsights = () => appInsights;

export function loadAppInsights() {
  if (!appInsights) {
    appInsights = new ApplicationInsights({
      config: {
        connectionString: useConfig()?.env?.appInsightsConnectionString,
        autoTrackPageVisitTime: true,
        extensions: [clickPluginInstance],
        extensionConfig: {
          [clickPluginInstance.identifier]: clickPluginConfig,
        },
      },
    });
  }

  appInsights.loadAppInsights();

  // Set cloud role name to be able to separate ppe / prod app insight signals
  appInsights.addTelemetryInitializer((item) => {
    if (!item.tags) {
      item.tags = [];
    }
    item.tags["ai.cloud.role"] = useConfig()?.env?.cloudRoleName;

    if (!item.data) {
      item.data = {};
    }

    // For manually tracked event, add some properties
    if (!item.data["uri"]) {
      item.data["uri"] = window.location.href;
    }

    if (!item.data["pageName"]) {
      item.data["pageName"] = window.document.title;
    }
  });
  appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview
}

export function setAuthenticatedUserContext(user?: User) {
  if (user && user.mail && user.id) {
    appInsights.setAuthenticatedUserContext(user.mail, user.id, true);

    return true;
  }

  return false;
}

/**
 * Tracks a custom event in Application Insights. This method should be used in
 * situations where the click event cannot be automatically tracked by the plugin.
 * @param name Event name
 * @param properties Added in the customDimensions field of the event.
 */
export function trackEvent(name: string, properties?: ICustomProperties) {
  appInsights.trackEvent({ name }, properties);
}

export function useAppInsights() {
  const { user } = useLogin();
  const location = useLocation();
  const config = useConfig();

  useEffect(() => {
    appInsights?.trackPageView();
  }, [location]);

  useEffect(() => {
    const isUserContextSet = setAuthenticatedUserContext(user);

    if (config?.env?.environment === "dev") {
      console.log("User context set: ", isUserContextSet);
    }
  }, [user]);

  return appInsights;
}
