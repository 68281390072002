import { Providers } from "@microsoft/mgt-element";
import { User } from "@microsoft/microsoft-graph-types";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

interface UserState {
  user: User | undefined;
  fetching: boolean;
  error: boolean;
}

const initialState: UserState = {
  user: undefined,
  fetching: false,
  error: false,
};

/**
 * This async thunk retrieves the user's information from the Microsoft Graph API
 * or returns the user if it has already been fetched.
 */
export const fetchUser = createAsyncThunk("user/fetchUser", async (_, { getState }) => {
  const state = getState() as RootState;
  const userState = state.user;

  if (userState.user !== undefined) {
    return userState.user;
  }

  const response = await Providers.globalProvider.graph.api("/me").select("displayName,givenName,id,mail,mailNickname").get();

  return response;
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.fetching = true;
        state.error = false;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.fetching = false;
        state.error = false;
      })
      .addCase(fetchUser.rejected, (state) => {
        state.fetching = false;
        state.error = true;
      });
  },
});

export default userSlice.reducer;

export const selectUser = (state: RootState) => state.user.user;
export const selectUserFetching = (state: RootState) => state.user.fetching;
export const selectUserState = createSelector([selectUser, selectUserFetching], (user, fetching) => ({ user, fetching }));
