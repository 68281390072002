import { Providers, ProviderState } from "@microsoft/mgt-element";
import { useIsSignedIn } from "@microsoft/mgt-react";
import { setUpAuthInterceptor } from "api/api";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "redux/hooks";
import { fetchUser, selectUser } from "redux/slices/userSlice";

export const useLogin = () => {
  const dispatch = useAppDispatch();
  const [signedIn] = useIsSignedIn();
  const user = useSelector(selectUser);
  const loading = Providers.globalProvider.state === ProviderState.Loading;

  useEffect(() => {
    if (signedIn) {
      setUpAuthInterceptor();
      dispatch(fetchUser());
    }
  }, [signedIn]);

  if (Providers.globalProvider.state === ProviderState.SignedOut) {
    Providers.globalProvider.login?.();
    return { loading: false, user };
  }

  return { loading, user };
};